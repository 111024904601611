import i18n from '../../i18n.config';
import { ERRORS } from '../constants';
import { MarketingFile, MarketingFolder } from '../types/marketing';
import apiClient from './client';

export const getMarketingFolders = async (
  folderId?: string
): Promise<MarketingFolder[] | Error> => {
  const query = folderId ? `?folderId=${folderId}` : '';
  const { statusCode, response } = await apiClient.get<MarketingFolder[]>(
    `api/marketing/sub-folders/list${query}`
  );

  if (statusCode === 200) {
    return response;
  }

  throw new Error(i18n.t(ERRORS.default));
};

export const getMarketingFiles = async (
  folderId: string,
  search?: string
): Promise<MarketingFile[] | Error> => {
  const searchQuery = search ? `&searchPhrase=${search}` : '';
  const { statusCode, response } = await apiClient.get<MarketingFile[]>(
    `api/marketing/folder/files?folderId=${folderId}${searchQuery}`
  );

  if (statusCode === 200) {
    return response;
  }

  throw new Error(i18n.t(ERRORS.default));
};
