import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../../components/Button/Button';
import EmptyState from '../../components/EmptyState/EmtyState';
import MainLayout from '../../components/MainLayout/MainLayout';
import ActionConfirmation from '../../components/Modals/ActionConfirmation/ActionConfirmation';
import NotificationCard from '../../components/NotificationCard/NotificationCard';
import NotificationForm from '../../components/NotificationForm/NotificationForm';
import {
  AllNotificationsResponse,
  deleteNotification,
  getAllNotifications,
  getNotifications,
  markAsRead,
  NotificationsResponse,
  removeNotifications
} from '../../core/api/notifications';
import {
  EditedNotification,
  FullNotification,
  Notification,
  NotificationItem
} from '../../core/types/user';
import i18n from '../../i18n.config';
import useUserStore from '../../store/user';
import styles from './styles.module.scss';

const PAGE_LIMIT = 8;

const NotificationsPage = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'notifications' });
  const { isAdmin } = useUserStore();
  const [notifications, setNotifications] = useState<NotificationItem[]>([]);
  const [allNotifications, setAllNotifications] = useState<FullNotification[]>(
    []
  );
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [selectedNotification, setSelectedNotification] =
    useState<FullNotification | null>(null);
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);

  const notificationToDeleteRef = useRef<number | null>(null);

  const handleMarkAsRead = async (markAll?: boolean) => {
    try {
      const ids = markAll ? notifications.map(({ id }) => id) : selectedIds;

      await markAsRead(ids);

      const updatedNotifications = notifications.reduce(
        (result, notification) => {
          if (ids.includes(notification.id))
            return [
              ...result,
              {
                ...notification,
                isRead: true,
                isSelected: false
              }
            ];

          return [...result, notification];
        },
        [] as NotificationItem[]
      );

      setNotifications(updatedNotifications);
    } catch (error) {
      console.error(error);
    }
  };

  const handleRemove = async (ids: number[]) => {
    try {
      await removeNotifications(ids);

      const newIds = selectedIds.filter(
        (selectedId) => !ids.includes(selectedId)
      );
      const newNotifications = notifications.filter(
        ({ id: currentId }) => !ids.includes(currentId)
      );

      setSelectedIds(newIds);
      setNotifications(newNotifications);
    } catch (error) {
      console.error(error);
    }
  };

  const toggleSelection = (notification: NotificationItem) => {
    if (notification.isSelected) {
      const newIds = selectedIds.filter((id) => id !== notification.id);
      setSelectedIds(newIds);
    } else {
      setSelectedIds([...selectedIds, notification.id]);
    }

    const notificationIndex = notifications.findIndex(
      ({ id }) => id === notification.id
    );

    const newNotifications = [...notifications];
    newNotifications[notificationIndex].isSelected =
      !newNotifications[notificationIndex].isSelected;

    setNotifications(newNotifications);
  };

  const fetchNotifications = async () => {
    try {
      setIsLoading(true);

      const pageToFetch = currentPage + 1;

      const response = await getNotifications(pageToFetch, PAGE_LIMIT);

      const { notifications: fetchedNotifications, totalCount: fetchedTotal } =
        response as NotificationsResponse;

      setTotalCount(fetchedTotal);
      const initSelectedIds = [] as number[];

      const formattedNotifications = (
        fetchedNotifications as Notification[]
      ).map((notification: Notification) => {
        notification.isRead && initSelectedIds.push(notification.id);

        return {
          ...notification,
          isSelected: notification.isRead
        };
      });

      setNotifications([...notifications, ...formattedNotifications]);
      setSelectedIds([...selectedIds, ...initSelectedIds]);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchAllNotifications = async (refetch?: boolean) => {
    setIsLoading(true);
    try {
      const page = refetch ? 1 : currentPage + 1;
      const response = await getAllNotifications(page, PAGE_LIMIT);

      const { notifications: fetchedNotifications, totalCount: fetchedCount } =
        response as AllNotificationsResponse;

      setAllNotifications([...allNotifications, ...fetchedNotifications]);
      setTotalCount(fetchedCount);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const refetchNotifications = () => {
    fetchAllNotifications(true);
  };

  const handleDelete = async () => {
    const notificationId = notificationToDeleteRef.current;
    if (!notificationId) return;
    try {
      await deleteNotification(notificationId);

      const newNotifications = allNotifications.filter(
        ({ id }) => id !== notificationId
      );

      setAllNotifications(newNotifications);
    } catch (error) {
      console.error(error);
    }
  };
  const handleEdit = (notification: FullNotification) => {
    setSelectedNotification(notification);
  };

  const updateSelectedNotification = (data: EditedNotification) => {
    const currentTranslation = data.translations[i18n.language];
    const updatedNotifications = allNotifications.reduce(
      (result, notification) => {
        if (notification.id === selectedNotification?.id) {
          return [
            ...result,
            {
              ...notification,
              title: currentTranslation.title,
              notification: currentTranslation.notification,
              translations: { ...data.translations }
            }
          ];
        }
        return [...result, notification];
      },
      [] as FullNotification[]
    );

    setAllNotifications(updatedNotifications);
  };

  useEffect(() => {
    if (!isLoading) {
      if (isAdmin) {
        fetchAllNotifications();
      } else {
        fetchNotifications();
      }
    }
  }, [currentPage, isAdmin]);

  const updateDate = moment().format('DD-MM/YYYY');
  const isLastPage = totalCount < PAGE_LIMIT * (currentPage + 1);

  const notificationsToDisplay = isAdmin ? allNotifications : notifications;

  const isEmpty = !notificationsToDisplay.length && !isLoading;
  return (
    <MainLayout>
      <section className={styles.notificationsPage}>
        <header className={styles.notificationsHeader}>
          <h1 className={styles.title}>{t('title')}</h1>
          <p className={styles.lastUpdate}>
            {t('last-updated')} {updateDate}
          </p>
          <div className={styles.headerButtons}>
            <Button
              view="transparent"
              className={styles.headerButton}
              buttonText={t('mark-selected-as-read')}
              onClick={() => handleMarkAsRead()}
            />
            <Button
              view="transparent"
              className={styles.headerButton}
              buttonText={t('remove-selected')}
              onClick={() => handleRemove(selectedIds)}
            />
            <Button
              view="transparent"
              className={styles.headerButton}
              buttonText={t('mark-all-as-read')}
              onClick={() => handleMarkAsRead(true)}
            />
          </div>
        </header>
        <main className={styles.notificationsMain}>
          {isAdmin && (
            <NotificationForm
              refetchNotifications={refetchNotifications}
              selectedNotification={selectedNotification}
              updateSelectedNotifications={updateSelectedNotification}
            />
          )}
          {isEmpty ? (
            <EmptyState text={t('empty-state-message')} />
          ) : (
            <>
              <div className={styles.notificationsList}>
                {!!notificationsToDisplay.length &&
                  notificationsToDisplay.map((notification) => (
                    <NotificationCard
                      key={notification.id}
                      notification={notification}
                      toggleSelect={() =>
                        toggleSelection(notification as NotificationItem)
                      }
                      handleRemove={() => handleRemove([notification.id])}
                      handleDelete={() => {
                        notificationToDeleteRef.current = notification.id;
                        setShowConfirmationModal(true);
                      }}
                      handleEdit={() =>
                        handleEdit(notification as FullNotification)
                      }
                      adminView={isAdmin}
                    />
                  ))}
              </div>
              {!isLastPage && (
                <Button
                  view="secondary"
                  className={styles.loadMore}
                  buttonText={t('load-more')}
                  onClick={() => setCurrentPage(currentPage + 1)}
                  disabled={isLoading}
                />
              )}
            </>
          )}
        </main>
      </section>
      {showConfirmationModal && (
        <ActionConfirmation
          confirmationText={t('remove-confirmation-text')}
          confirmText={t('confirmation-yes')}
          cancelText={t('confirmation-no')}
          onClose={() => setShowConfirmationModal(false)}
          handleCancel={() => setShowConfirmationModal(false)}
          handleConfirm={handleDelete}
        />
      )}
    </MainLayout>
  );
};

export default NotificationsPage;
