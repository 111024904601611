import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import Input from '../../components/Input/Input';
import MainLayout from '../../components/MainLayout/MainLayout';
import MarketingFileCard from '../../components/MarketingFileCard/MarketingFileCard';
import { getMarketingFiles } from '../../core/api/marketing';
import { FaIcons } from '../../core/constants';
import { MarketingFile } from '../../core/types/marketing';
import useDebouncedCallback from '../../hooks/useDebouncedCallback';
import useMarketingStore from '../../store/marketingFolders';
import styles from './styles.module.scss';

const MarketingPage = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'marketing' });
  const { folderId } = useParams();
  const { folders } = useMarketingStore();
  const [prevSearch, setPrevSearch] = useState<string>('');
  const [searchValue, setSearchValue] = useState<string>('');
  const currentFolder = folders.find(({ id }) => Number(folderId) === id);
  const [files, setFiles] = useState<MarketingFile[]>([]);

  const fetchFiles = async () => {
    try {
      const fetchedFiles = await getMarketingFiles(
        folderId as string,
        searchValue
      );
      setFiles(fetchedFiles as MarketingFile[]);
      setPrevSearch(searchValue);
    } catch (error) {
      console.error(error);
      setFiles([]);
    }
  };

  const startSearch = useDebouncedCallback(() => fetchFiles(), 500);

  useEffect(() => {
    if (prevSearch.length || searchValue.length) startSearch();
  }, [searchValue]);

  useEffect(() => {
    fetchFiles();
  }, [folderId]);

  return (
    <MainLayout>
      <main>
        <section className={styles.marketingSection}>
          <header className={styles.sectionHeader}>
            <h1 className={styles.sectionTitle}>{currentFolder?.title}</h1>
            <Input
              name="item search"
              placeholder={t('search-placeholder')}
              onChange={(e) => setSearchValue(e.target.value)}
              value={searchValue}
              Icon={FaIcons.searchIcon}
              iconClassName={styles.searchIcon}
              inputClassName={styles.searchInput}
            />
          </header>
          <main className={styles.sectionMain}>
            <div className={styles.marketingItems}>
              {files.map((file) => (
                <MarketingFileCard key={file.id} file={file} />
              ))}
            </div>
          </main>
        </section>
      </main>
    </MainLayout>
  );
};

export default MarketingPage;
