import { MarketingFile } from '../../core/types/marketing';
import DownloadOverlay from '../DownloadOverlay/DownloadOverlay';
import styles from './styles.module.scss';

const MarketingFileCard = ({ file }: { file: MarketingFile }) => {
  return (
    <div className={styles.fileCard}>
      {file.type === 'pdf' ? (
        <iframe
          className={styles.pdf}
          src={`${file.preview}#page=1&zoom=1,6,0`}
        />
      ) : (
        <img src={file.preview} className={styles.fileImg} />
      )}
      <p className={styles.fileName}>{file.filename}</p>
      <DownloadOverlay view="light" link={file.original} />
    </div>
  );
};

export default MarketingFileCard;
