import classNames from 'classnames';
import moment from 'moment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FaIcons } from '../../core/constants';
import { FullNotification, NotificationItem } from '../../core/types/user';
import Button from '../Button/Button';
import FaIcon from '../FaIcon';
import styles from './styles.module.scss';

interface IProps {
  notification: NotificationItem | FullNotification;
  toggleSelect: () => void;
  handleRemove: () => void;
  handleDelete: () => void;
  handleEdit: () => void;
  adminView?: boolean;
}

const CHAR_LIMIT = 300;

const NotificationCard = ({
  notification,
  toggleSelect,
  handleRemove,
  handleDelete,
  handleEdit,
  adminView
}: IProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'notifications' });
  const [showMore, setShowMore] = useState<boolean>(false);

  const fullText = notification.notification;
  const shortText = fullText.slice(0, CHAR_LIMIT) + '...';
  const isLongText = fullText.length > CHAR_LIMIT;
  const displayedText = !isLongText || showMore ? fullText : shortText;

  const formattedDate = moment(notification.notificationDate).format(
    'DD-MM-YYYY'
  );

  const selectIcon = (notification as NotificationItem).isSelected
    ? FaIcons.checkIcon
    : FaIcons.squareIcon;

  return (
    <div
      className={classNames(styles.notificationCard, {
        [styles.selected]: (notification as NotificationItem).isSelected,
        [styles.unread]: !(notification as NotificationItem).isRead,
        [styles.adminView]: adminView
      })}
    >
      {!adminView && (
        <Button
          view="transparent"
          className={styles.selectButton}
          onClick={toggleSelect}
        >
          <FaIcon faName={selectIcon} />
        </Button>
      )}
      {adminView && (
        <div className={styles.adminControls}>
          <Button view="transparent" className={styles.iconButton}>
            <FaIcon faName={FaIcons.penIcon} onClick={handleEdit} />
          </Button>
          <Button view="transparent" className={styles.iconButton}>
            <FaIcon faName={FaIcons.trashCanIcon} onClick={handleDelete} />
          </Button>
        </div>
      )}
      <div className={styles.content}>
        <p className={styles.date}>{formattedDate}</p>
        <h2 className={styles.title}>{notification.title}</h2>
        <p className={styles.notificationText}>{displayedText}</p>
        {isLongText && (
          <Button
            view="transparent"
            buttonText={showMore ? t('show-less') : t('show-more')}
            className={styles.readMoreButton}
            onClick={() => setShowMore(!showMore)}
          />
        )}
      </div>
      {!adminView && (
        <Button view="transparent" className={styles.iconButton}>
          <FaIcon faName={FaIcons.trashCanIcon} onClick={handleRemove} />
        </Button>
      )}
    </div>
  );
};

export default NotificationCard;
